import React, { Component } from 'react';
import { Flipper } from 'react-flip-toolkit';

import CompanyDropdown from './CompanyDropdown';
import DropdownContainer from './components/DropdownContainer';
import { DropdownGroupContext } from './components/DropdownGroupContext';
import { HeaderItem } from './components/HeaderItem';
import ForHealthPlansDropdown from './ForHealthPlansDropdown';
import ForProvidersDropdown from './ForProvidersDropdown';
import GetCareDropdown from './GetCareDropdown';

//NOTE: All aria suggestions for this component come from https://www.evinced.com/blog/a11y-nav-menus/

const DURATION = 300;

const navbarConfig = [
  { title: 'Get Care', dropdown: GetCareDropdown },
  { title: 'For Providers', dropdown: ForProvidersDropdown },
  { title: 'For Health Plans', dropdown: ForHealthPlansDropdown },
  { title: 'Company', dropdown: CompanyDropdown },
];

type Direction = 'left' | 'right';
type State = {
  activeIndices: number[];
  animatingOut: boolean;
};
type DropdownGroupProps = {
  'aria-label': string;
};

type DropdownComponentType =
  | typeof ForHealthPlansDropdown
  | typeof ForProvidersDropdown
  | typeof GetCareDropdown;

export class DropdownGroup extends Component<DropdownGroupProps, State> {
  state: State = {
    activeIndices: [],
    animatingOut: false,
  };

  animatingOutTimeout: undefined | number = undefined;

  resetDropdownState = (i?: number) => {
    this.setState({
      activeIndices: i ? [i] : [],
      animatingOut: false,
    });
    delete this.animatingOutTimeout;
  };

  onMouseEnter = (i: number) => {
    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout);
      this.resetDropdownState(i);
      return;
    }
    if (this.state.activeIndices[this.state.activeIndices.length - 1] === i)
      return;

    this.setState((prevState: State) => ({
      activeIndices: prevState.activeIndices.concat(i),
      animatingOut: false,
    }));
  };

  onMouseLeave = () => {
    this.setState({
      animatingOut: true,
    });
    this.animatingOutTimeout = window.setTimeout(
      this.resetDropdownState,
      DURATION
    );
  };

  render() {
    let CurrentDropdown: DropdownComponentType;
    let PrevDropdown: DropdownComponentType;
    let direction: Direction;

    const currentIndex =
      this.state.activeIndices[this.state.activeIndices.length - 1];
    const prevIndex =
      this.state.activeIndices.length > 1 &&
      this.state.activeIndices[this.state.activeIndices.length - 2];

    if (typeof currentIndex === 'number')
      CurrentDropdown = navbarConfig[currentIndex].dropdown;
    if (typeof prevIndex === 'number') {
      PrevDropdown = navbarConfig[prevIndex].dropdown;
      direction = currentIndex > prevIndex ? 'right' : 'left';
    }

    return (
      <Flipper flipKey={currentIndex} spring={'noWobble'}>
        <nav
          onMouseLeave={this.onMouseLeave}
          {...this.props}
          aria-label={this.props['aria-label']}
        >
          <ul
            role="menubar"
            aria-label={this.props['aria-label']}
            css={{
              display: 'flex',
              justifyContent: 'center',
              listStyle: 'none',
              margin: 0,
              padding: 0,
            }}
          >
            {navbarConfig.map(({ title, dropdown: Dropdown }, index) => {
              return (
                <HeaderItem
                  key={title}
                  title={title}
                  index={index}
                  currentIndex={currentIndex}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                >
                  {currentIndex === index ? (
                    <DropdownContainer
                      direction={direction}
                      animatingOut={this.state.animatingOut}
                      duration={DURATION}
                    >
                      <DropdownGroupContext.Provider value={{ isActive: true }}>
                        <CurrentDropdown />
                      </DropdownGroupContext.Provider>

                      {PrevDropdown && <PrevDropdown aria-hidden="true" />}
                    </DropdownContainer>
                  ) : (
                    <div
                      css={{
                        position: 'absolute',
                        top: 'calc(100% - 10px)',
                        left: '-999em',
                      }}
                      aria-hidden="true"
                    >
                      <Dropdown />
                    </div>
                  )}
                </HeaderItem>
              );
            })}
          </ul>
        </nav>
      </Flipper>
    );
  }
}
