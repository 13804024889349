import { BillingType } from '@headway/api/models/BillingType';
import { UserApi } from '@headway/api/resources/UserApi';
import {
  QueryKey,
  useQuery,
  UseQueryResult,
} from '@headway/shared/react-query';

export const shouldShowAnthemEAPExperienceQueryKey = (
  userId: number | undefined,
  providerId: number | undefined,
  appointmentBillingType: BillingType | undefined
): QueryKey => [
  'should-show-anthem-eap-experience',
  userId,
  providerId,
  appointmentBillingType,
];

export const useShouldShowAnthemEAPExperience = (
  userId: number | undefined,
  providerId: number | undefined,
  appointmentBillingType: BillingType | undefined
): UseQueryResult<boolean | undefined> =>
  useQuery(
    shouldShowAnthemEAPExperienceQueryKey(
      userId,
      providerId,
      appointmentBillingType
    ),
    () => {
      if (!userId) {
        return false;
      }
      return UserApi.shouldShowAnthemEapExperience(userId, {
        provider_id: providerId,
        appointment_billing_type: appointmentBillingType,
      });
    },
    {
      staleTime: Infinity,
    }
  );
